import React from 'react'
import Navigation from './navigation.js'
import Footer from './footer.js'


export default function Layout(props) {
    return (
        <React.Fragment>
            <main>{props.children}</main>
    </React.Fragment>
    )


}

