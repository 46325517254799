// extracted by mini-css-extract-plugin
export const header = "index-module--header--GkXlg";
export const headerImage = "index-module--headerImage--1rqQL";
export const remax = "index-module--remax--1zb81";
export const headerImageRemaxBalon = "index-module--headerImageRemaxBalon--1LjlC";
export const headerContent = "index-module--headerContent--2BHIj";
export const content = "index-module--content--2SsJM";
export const about = "index-module--about--2Gkvm";
export const aboutContent = "index-module--aboutContent--3zk2f";
export const aboutContentTitle = "index-module--aboutContentTitle--3v0Bk";
export const aboutImageContainer = "index-module--aboutImageContainer--ksJ7y";
export const aboutButton = "index-module--aboutButton--2U4t8";
export const portfolio = "index-module--portfolio--3VXF6";
export const portfolioContent = "index-module--portfolioContent--2iQuy";
export const portfolioButton = "index-module--portfolioButton--347J6";