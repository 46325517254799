import React from "react"
import Layout from "../components/layout.js"
import SEO from "../components/seo.js"
import Fade from 'react-reveal/Fade';
import { navigate } from "@reach/router"
import Button from "react-bootstrap/Button"

import header_img from "../images/header.jpg"
import portray from "../images/portray.jpg"
import remax from "../images/remax.png"
import remaxbalon from "../images/remaxbalon.png"
import { StaticImage } from "gatsby-plugin-image"
import { HiArrowRight } from "@react-icons/all-files/hi/HiArrowRight"

import * as styles from "./index.module.css"

export default function Home() {
  return (
  <React.Fragment>
    <Layout page="home">
      <div className={styles.header}>

        <StaticImage className={styles.headerImage} src="../images/header.jpg" alt={"Header"} />
        <div className={styles.headerContent}>
          <Fade left><h1>Serap Özer</h1></Fade>
          <Fade left><p>Yapım Aşamasında...</p></Fade>
        </div>
      </div>

    </Layout>
    <SEO />
    </React.Fragment>
  )
}